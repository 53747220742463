import React from 'react';

import PageContentWrapper from '../components/PageContentWrapper';
import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';

import GedserQuiz from '../components/GedserQuiz';

export default class Kort extends React.Component {
	
	render() {
		return (
			<article className='Page Quiz'>
				<GedserSign/>
				<VerticalNavigation/>
				
				<PageContentWrapper>
					<GedserQuiz/>
				</PageContentWrapper>
			</article>
		)
	}
};
