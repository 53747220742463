import React, { Component } from 'react'
import EventItem from './EventItem'
import axios from 'axios'
import SyncLoader from "react-spinners/SyncLoader";

export class Events extends Component {
    state = {
        events: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get('/wordpress/wp-json/wp/v2/events')
        .then(res => this.setState({
            events: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }

    render() {
        const { events, isLoaded } = this.state;
        if(isLoaded) {
            return (
                <div>
                    { events.map(event => (
                        event.acf.language === this.props.language ? <EventItem key={event.id} event={event} /> : ""
                    ))}
                </div>
            );
        }

        return  <SyncLoader
                    css={`
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 25px;
                        width: 57px;
                    `}
                    size={15}
                    color={"#2f6bb1"}
                    loading={true}
                />
    }
}

export default Events
