import React from 'react';

import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';

import PageContentWrapper from '../components/PageContentWrapper';
import SyncLoader from "react-spinners/SyncLoader";

import LanguageContext from '../config/LanguageContext';

// import draftToHtml from 'draftjs-to-html';

const style = {
	tak: {
		color: '#d54d7b', 
		fontFamily: '"Great Vibes", Damion, cursive',
		fontSize: '165px', 
		lineHeight: '160px', 
		fontWeight: 'normal', 
		marginBottom: '0px', 
		marginTop: '40px', 
		textAlign: 'center', 
		textShadow: '0 1px 1px #fff',
		display: 'inline-block'
	},
	p: {
		color: 'rgb(43, 52, 66)',
		fontSize: '29px',
		fontFamily: '"Libre Baskerville", serif',
		lineHeight: '45px',
		textAlign: 'center',
		textShadow: '#ffffff 0px 1px 21px',
		padding: '0 15px',
		// paddingBottom: '200px',
	}
}

class BaseTaksigelse extends React.Component {
	state = {thanks: [], isLoaded: false}
	componentDidMount(){
		console.log(this.props.language);
		
		fetch('/wordpress/wp-json/wp/v2/thanks')
		.then(res => res.json())
		.then(thanks => {
			thanks.map(thank => {
				if (thank.acf.language === this.props.language) 
					this.setState({ thanks: [thank], isLoaded: true })
			});
			
		});
	}

	logLanguage = () => {
		console.log(this.props.language);
	}

	render () {
		const {thanks, isLoaded} = this.state;
		if (isLoaded)
			return (
				<article className='Page Taksigelse' onClick={this.logLanguage}>
					<GedserSign/>
					<VerticalNavigation/>
					
					<PageContentWrapper>
					
					
							<h1>{thanks[0].title.rendered}</h1>
							<div className="Container" style={style.p} dangerouslySetInnerHTML={{__html: thanks[0].acf.article}}></div>
							{/* <span style={style.tak}>{content.endtitle}</span> */}
				
				
					</PageContentWrapper>
				</article>
			)
		return (
			<article className='Page Taksigelse' onClick={this.logLanguage}>
					<GedserSign/>
					<VerticalNavigation/>
					
					<PageContentWrapper>
					
					
					<SyncLoader
						css={`
							margin-left: auto;
							margin-right: auto;
							margin-bottom: 25px;
							width: 57px;
						`}
						size={15}
						color={"#2f6bb1"}
						loading={true}
					/>
				
				
					</PageContentWrapper>
				</article>
		)
}
}

// higher order component (HOC) for accessing language consumer in Taksigelse fetch
export default class Taksigelse extends React.Component {
	render () {
		return (
			<LanguageContext.Consumer>
				{({ language }) =>
				<BaseTaksigelse language={language} />
				}
			</LanguageContext.Consumer>
		)}
} 