import React from 'react';

import PageContentWrapper from '../components/PageContentWrapper';
import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import LanguageContext from '../config/LanguageContext';

export default () => (
	<article className='Page Overnatninger'>
		<GedserSign/>
		<VerticalNavigation/>
		
		<LanguageContext.Consumer>
			{({ language }) =>
				<PageContentWrapper>
					<h1>{language == "da-DK" ? 'Overnatninger' : 'Unterkunft'}</h1>
					<PageContent/>
				</PageContentWrapper>
			}
		</LanguageContext.Consumer>
	</article>
);

function PageContent() {
	return (
	  <div>
		<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
			<a className='noText' href="https://gedsernaturcenter.dk/" target="_blank" rel="noopener">
				gedsernaturcenter.dk
				<img style={{marginBottom: 0, marginTop: 0, marginLeft: 3,}} className="custom-btn" src="/img/button/new/naturcenter.png" alt="Til gedsernaturcenter.dk træskilt"/>
			</a>
			<a className='noText' href="https://www.booking.com/searchresults.de.html?label=gen173nr-1FCAEoggJCAlhYSDNYBGg9iAEBmAEHwgEKd2luZG93cyAxMMgBDNgBAegBAfgBC5ICAXmoAgM&amp;sid=36db7c011d78487a7482cba0e9ee3eee&amp;sb=1&amp;src=index&amp;src_elem=sb&amp;error_url=https%3A%2F%2Fwww.booking.com%2Findex.de.html%3Flabel%3Dgen173nr-1FCAEoggJCAlhYSDNYBGg9iAEBmAEHwgEKd2luZG93cyAxMMgBDNgBAegBAfgBC5ICAXmoAgM%3Bsid%3D36db7c011d78487a7482cba0e9ee3eee%3Bsb_price_type%3Dtotal%26%3B&amp;ss=gedser&amp;checkin_monthday=&amp;checkin_month=&amp;checkin_year=&amp;checkout_monthday=&amp;checkout_month=&amp;checkout_year=&amp;group_adults=2&amp;group_children=0&amp;no_rooms=1" target="_blank" rel="noopener">
				booking.com
				<img style={{marginBottom: 0, marginTop: 0,}} className="custom-btn" src="/img/button/new/booking.png" alt="Til booking.com træskilt" />
			</a>
			<a className='noText' href="https://de.airbnb.com/s/Gedser--Denmark/homes?refinement_paths%5B%5D=%2Fhomes&amp;allow_override%5B%5D=&amp;s_tag=6iojnKLn" target="_blank" rel="noopener">
				airbnb.com
				<img style={{marginBottom: 0, marginTop: 0, marginRight: 9}} className="custom-btn" src="/img/button/new/airbnb.png" alt="Til airbnb træskilt" />
			</a>
		</div>
	</div>
	);
  }