import React from 'react';
import parse from 'html-react-parser'

import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import PageContentWrapper from '../components/PageContentWrapper';
import SyncLoader from "react-spinners/SyncLoader";

import { Link } from 'react-router-dom';
// import ArrowLinks from '../components/ArrowLinks';

import LanguageContext from '../config/LanguageContext';

import '../components/css/ArrowLinks.css';

let attractionArray = [];

class BaseSevaerdighedDetail extends React.Component {

	state = {
		nextAttraction: '',
		previousAttraction: '',
		attraction: [],
		isLoaded: false
	}

	initAttractionArrow = () => {
		
		let attraction = this.state.attraction[0];
		console.log(attraction);
		console.log(attractionArray);
		let attractionIndex = attractionArray.indexOf(attraction.id);

		console.log(attractionIndex);

		if (attractionIndex < attractionArray.length-1 && attractionIndex > 0) {
			this.setState({nextAttraction: `/Sevaerdigheder/${attractionArray[attractionIndex+1]}`});
			this.setState({previousAttraction: `/Sevaerdigheder/${attractionArray[attractionIndex-1]}`});
			console.log("13");
		} else if ((attractionIndex == 0) && attractionArray.length > 1) {
			this.setState({nextAttraction: `/Sevaerdigheder/${attractionArray[1]}`});
			this.setState({previousAttraction: `/Sevaerdigheder/${attractionArray[attractionArray.length-1]}`});
			console.log("2");
		} else if ((attractionIndex == attractionArray.length -1) && attractionArray.length > 1) {
			this.setState({nextAttraction: `/Sevaerdigheder/${attractionArray[0]}`});
			this.setState({previousAttraction: `/Sevaerdigheder/${attractionArray[attractionIndex -1]}`});
			console.log("3");
		} else if ((attractionIndex == 0) && attractionArray.length == 1) {
			this.setState({nextAttraction: `/Sevaerdigheder/${attractionArray[0]}`});
			this.setState({previousAttraction: `/Sevaerdigheder/${attractionArray[0]}`});
			console.log("4");
		} 
	}
	
	componentDidMount () {		
		fetch(`https://gedser.info/wordpress/wp-json/wp/v2/attractions/?per_page=100`)
		.then(res => res.json())
		.then(attractions => {
			
			attractions.map(attraction => {
				if (attraction.acf.language === this.props.language) 
					attractionArray.push(attraction.id);
			})
			attractionArray.reverse();
			console.log(this.props.language);
			console.log(attractionArray);

			this.showAttraction();
		});

	}

	// componentDidUpdate() {
	// 	if (this.state.attraction[0].id.toString() !== this.props.attraction)
	// 		this.forceUpdateHandler()

	// 		console.log('this.state.attraction[0].id ', this.state.attraction[0].id )
	// 		console.log('this.props.attraction', this.props.attraction)
	// }

	forceUpdateHandler = () => {		
		attractionArray = [];
		// this.forceUpdate();
		this.setState({
			isLoaded: false
		})
		this.showAttraction();
		// this.initAttractionArrow();
		
		document.querySelector('.page-content').scrollTo(0, 0); // values are x,y-offset
	};

	showAttraction = () => {
		attractionArray = [];
		fetch(`https://gedser.info/wordpress/wp-json/wp/v2/attractions/?per_page=100`)
		.then(res => res.json())
		.then(attractions => {

			console.log('attractions', attractions)
			
			attractions.map(attraction => {
				if (attraction.acf.language === this.props.language) 
					attractionArray.push(attraction.id);
			})
			
			fetch(`https://gedser.info/wordpress/wp-json/wp/v2/attractions/${this.props.attraction}`)
				.then(res => res.json())
				.then(attraction => {
					console.log('attraction', attraction)
					this.setState({ attraction:[attraction], isLoaded: true });
					this.initAttractionArrow();
					if (!(typeof attraction.id !== 'undefined')) {
						window.location.href = `/Sevaerdigheder/${attractionArray[0]}`;
					}
					// return attraction;
				})
				// .then(attraction => {
				// 	if (typeof attraction.id !== 'undefined') {
				// 		if (document.querySelector('#articleWrapper') !== null)
				// 			document.querySelector('#content').removeChild(document.querySelector('#articleWrapper'));
		
				// 		let wrapper = document.createElement('div');
				// 		wrapper.id = 'articleWrapper';
		
				// 		wrapper.innerHTML = attraction.acf.article;
				// 		document.querySelector('#content').appendChild(wrapper);
				// 	} else {
				// 		window.location.href = `/Sevaerdigheder/${attractionArray[0]}`;
				// 	}
				// });
		
		});
		
	}

	render () {
		const { attraction, isLoaded } = this.state;
		if (isLoaded)
			return (
				<article className='Page Sevaerdighed'>
					<GedserSign/>
					<VerticalNavigation/>

					<PageContentWrapper>
						{this.state.attraction.map(attraction =>
						<div id="content" key={attraction.id}>
							<h1>{parse(attraction.title.rendered)}</h1>
							<div>
								{isLoaded && parse(attraction.acf.article)}
								{/* {!isLoaded && <SyncLoader
													css={`
														margin-left: auto;
														margin-right: auto;
														width: 57px;
													`}
													size={15}
													color={"#2f6bb1"}
													loading={true}
												/>} */}
							</div>
						</div>
						)}
						
						{/* <ArrowLinks previous={this.state.previousAttraction} next={this.state.nextAttraction}/> */}

						<div className='arrowLinks' style={{display: 'flex', justifyContent: 'space-around', paddingBottom: '200px'}}>
							{console.log('attractionArray', attractionArray)}
							<Link to={this.state.previousAttraction} onClick={this.forceUpdateHandler} ><img src="/img/Attractions/svg/arrow.svg" alt="next attraction"/></Link>
							<Link to={this.state.nextAttraction} onClick={this.forceUpdateHandler}><img src="/img/Attractions/svg/arrow.svg" alt="previous attraction" style={{transform: 'scaleX(-1)'}}/></Link>
						</div>

					</PageContentWrapper>

				</article>
			);
		return (
			<article className='Page Sevaerdighed'>
				<GedserSign/>
				<VerticalNavigation/>

				<PageContentWrapper>
					
					<div id="content">
						<SyncLoader
							css={`
								margin-left: auto;
								margin-right: auto;
								margin-bottom: 25px;
								width: 57px;
							`}
							size={15}
							color={"#2f6bb1"}
							loading={true}
						/>
					</div>
				</PageContentWrapper>

			</article>
		);
	}
}


// higher order component (HOC) for accessing language consumer in Sevaerdiheder fetch
export default class SevaerdighedDetail extends React.Component {

	render () {
		return (
			<LanguageContext.Consumer>
				{({ language }) =>
				<BaseSevaerdighedDetail language={language} attraction={this.props.match.params.attraction} />
				}
			</LanguageContext.Consumer>
	)}
} 