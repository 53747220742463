import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class PlakatSlider extends React.Component {
	render() {
	  const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	  };
	  return (
		<div style={{padding: '30px 0', overflow: 'hidden'}} >
		  <Slider {...settings}>
			<div>
			  	<img src="/img/KunstKultur/PlakatSlider/plakat1.jpeg" alt="plakat1" style={{margin: '0 auto' }} />
			</div>
			<div>
				<img src="/img/KunstKultur/PlakatSlider/plakat2.jpeg" alt="plakat2" style={{margin: '0 auto' }} />
			</div>
			<div>
				<img src="/img/KunstKultur/PlakatSlider/plakat3.jpeg" alt="plakat3" style={{margin: '0 auto' }} />
			</div>
			<div>
				<img src="/img/KunstKultur/PlakatSlider/plakat4.jpeg" alt="plakat4" style={{margin: '0 auto' }} />
			</div>
		  </Slider>
		</div>
	  );
	}
  }