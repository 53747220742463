import React from 'react';

import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import PageContentWrapper from '../components/PageContentWrapper';

import AttractionsList from '../components/AttractionsList';
import LanguageContext from '../config/LanguageContext';

class BaseSevaerdigheder extends React.Component {
	// state = {thanks: []}
	// componentDidMount(){
	// 	console.log(this.props.language);
		
	// 	fetch('/thanks/'+this.props.language)
	// 	.then(res => res.json())
	// 	.then(thanks => this.setState({ thanks }))
	// }

	logLanguage = () => {
		console.log(this.props.language);
	}

	render () {
		return (
		<article className='Page Sevaerdigheder'>
			<GedserSign/>
			<VerticalNavigation/>

			<PageContentWrapper>
				<h1>{this.props.language == "da-DK" ? 'Seværdigheder' : 'Sehenswürdigkeiten'}</h1>
				<AttractionsList/>
			</PageContentWrapper>
		
		</article>
	)}
}

// higher order component (HOC) for accessing language consumer in Sevaerdigheder fetch
export default class Sevaerdigheder extends React.Component {
	render () {
		return (
			<LanguageContext.Consumer>
				{({ language }) =>
				<BaseSevaerdigheder language={language} />
				}
			</LanguageContext.Consumer>
		)}
} 