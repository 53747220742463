import React from 'react';
import { Redirect } from 'react-router-dom';

import './css/Sailboats.css';

import LanguageContext from '../config/LanguageContext';

export default class Sailboats extends React.Component {
	state = {
		redirect: false,
		redirectPath: 'kort',
	}

	componentDidMount() {
		let that = this;

			let sb1 = document.getElementsByClassName("sailboat")[0];
			let sb2 = document.getElementsByClassName("sailboat")[1];
			let sb1_title = "Velkommen";
			let sb2_title = "Willkommen";
		  
			//welcome animation
			let createSmoke = () => {
			  let text_cont1 = document.createElement("div");
			  let text_cont2 = document.createElement("div");
			  text_cont1.className = 'center';
			  text_cont2.className = 'center';
			  let text_cont1_ul = document.createElement("ul");
			  let text_cont2_ul = document.createElement("ul");
		  
			  for (var i = 0; i < sb1_title.length; i++) {
				let li = document.createElement("li"); 
				let litext = document.createTextNode(sb1_title.charAt(i)); 
				li.appendChild(litext); 
				text_cont1_ul.appendChild(li);
			  }
			  for (i = 0; i < sb2_title.length; i++) {
				let li = document.createElement("li"); 
				let litext = document.createTextNode(sb2_title.charAt(i)); 
				li.appendChild(litext); 
				text_cont2_ul.appendChild(li);
			  }
		  
			  text_cont1.appendChild(text_cont1_ul);
			  text_cont2.appendChild(text_cont2_ul);
			  sb1.appendChild(text_cont1);
			  sb2.appendChild(text_cont2);
			}
			createSmoke();
		  
			//trigger animation on hover
			sb1.addEventListener("mouseover", function( event ) {
			  sb1.querySelector(".center").querySelectorAll("li").forEach(function (element) {
				element.style.animation = "smoke 1s linear backwards 1";
				element.style.opacity = "1";
			  });
			}, false);
			sb2.addEventListener("mouseover", function( event ) {
			  sb2.querySelector(".center").querySelectorAll("li").forEach(function (element) {
				element.style.animation = "smoke 1s linear backwards 1";
				element.style.opacity = "1";
			  });
			}, false);
			
				//link timeout
				// document.querySelector(".sailboat").addEventListener("click", function(){
				// 	setTimeout(function(){that.setState({redirect: true, redirectPath: "kort"});}, 2000);
				// });
				// document.querySelectorAll(".sailboat")[1].addEventListener("click", function(){
				// 	setTimeout(function(){that.setState({redirect: true, redirectPath: "kortDe"});}, 2000);
				// });
	}

	redirectToPage = () => {
		setTimeout(function(){this.setState({redirect: true, redirectPath: "kort"});}.bind(this), 2000);
	}
	

  render() {
	if (this.state.redirect) {
		return <Redirect push to={"/" + this.state.redirectPath} />;
	}
    return (
			<LanguageContext.Consumer>
			{({ updateLanguage }) => (
				
				<div className="sb_container">
					<div data-lang="da-DK" className="sailboat" onClick={(e) => {updateLanguage(e); this.redirectToPage()}}>
						{/* <div data-lang="da-DK" className="sailboat" onClick={(e) => {this.redirectToPage()}}> */}
							<img src="/img/Sailboats/sailboat_dk.svg" alt="Sail Boat"/>
							<div className="water">
								<div className="drops clearfix drops-1">
									<span className="drop drop-a"></span>
									<span className="drop drop-b"></span>
									<span className="drop drop-c"></span>
									<span className="drop drop-d"></span>
									<span className="drop drop-e"></span>
									<span className="drop drop-f"></span>
									<span className="drop drop-g"></span>
									<span className="drop drop-h"></span>
								</div>
								<div className="drops clearfix drops-2">
									<span className="drop drop-a"></span>
									<span className="drop drop-b"></span>
									<span className="drop drop-c"></span>
									<span className="drop drop-d"></span>
									<span className="drop drop-e"></span>
									<span className="drop drop-f"></span>
									<span className="drop drop-g"></span>
									<span className="drop drop-h"></span>
								</div>
							</div>
						{/* </div> */}
					</div>

					<div data-lang="de-DE" className="sailboat" onClick={(e) => {updateLanguage(e); this.redirectToPage()}}>
						{/* <div data-lang="de-DE" className="sailboat" onClick={(e) => {this.redirectToPage()}}> */}
							<img src="/img/Sailboats/sailboat_de.svg" alt="Sail Boat"/>
							<div className="water">
								<div className="drops clearfix drops-1">
									<span className="drop drop-a"></span>
									<span className="drop drop-b"></span>
									<span className="drop drop-c"></span>
									<span className="drop drop-d"></span>
									<span className="drop drop-e"></span>
									<span className="drop drop-f"></span>
									<span className="drop drop-g"></span>
									<span className="drop drop-h"></span>
								</div>
								<div className="drops clearfix drops-2">
									<span className="drop drop-a"></span>
									<span className="drop drop-b"></span>
									<span className="drop drop-c"></span>
									<span className="drop drop-d"></span>
									<span className="drop drop-e"></span>
									<span className="drop drop-f"></span>
									<span className="drop drop-g"></span>
									<span className="drop drop-h"></span>
								</div>
							</div>
						</div>
					{/* </div> */}
				</div>
			)}
		</LanguageContext.Consumer>
    )
  }


}
