import React from 'react';

import ContentContext from '../config/ContentContext';
import navhighlight from'../nav-highlight.png';

import './css/CustomMap.css';
import Hammer from 'hammerjs';

import Map from '../SVG/gedser_map.svg'

const style = {
	position: 'absolute',
	left: 6,
	top: 2,
	zIndex: 5,
	width: 250,
	pointerEvents: 'none'
	
}

export default class CustomMap extends React.Component {
	state={
		uB: false
	}

	componentDidMount(){

		let containerElement = document.querySelector (".CustomMap");
		var image = document.getElementById('gedser_map');

		var mc = new Hammer.Manager(image);

		var pinch = new Hammer.Pinch();
		var pan = new Hammer.Pan();
		var singleTap = new Hammer.Tap({ event: 'singletap' });

		pinch.recognizeWith(pan);

		mc.add([pinch, pan, singleTap]);

		// mc.on("singletap", function() {
		// 	console.log('hi');
		// });

		var adjustScale = 1;
		var adjustDeltaX = 0;
		var adjustDeltaY = 0;

		var currentScale = null;
		var currentDeltaX = null;
		var currentDeltaY = null;

		// Prevent long press saving on mobiles.
		containerElement.addEventListener('touchstart', function (e) {
			e.preventDefault()
		});

		// Handles pinch and pan events/transforming at the same time

		mc.on("pinch pan", function (ev) {

			// console.log ("panning");																																

			var transforms = [];

			// Adjusting the current pinch/pan event properties using the previous ones set when they finished touching
			currentScale = adjustScale * ev.scale;
			currentDeltaX = adjustDeltaX + (ev.deltaX / currentScale);
			currentDeltaY = adjustDeltaY + (ev.deltaY / currentScale);

			transforms.push(`scale(${currentScale})`);
			transforms.push(`translate(${currentDeltaX}px,${currentDeltaY}px)`);

			containerElement.style.transform = transforms.join(' ');

		});


		mc.on("panend pinchend", function (ev) {

			// Saving the final transforms for adjustment next time the user interacts.
			adjustScale = currentScale;
			adjustDeltaX = currentDeltaX;
			adjustDeltaY = currentDeltaY;

		});
	}

	RenderMap() {
		return (
			<img id="gedser_map" src={Map} alt='custom gedser map'/>
		);
	  }

	render() {
		return (
			<div className='map-wrapper'>
		
				
				<ContentContext.Consumer>
				{Content => (
					<div className='CustomMap' onClick={Content.undoBlur}>
					 {this.RenderMap()}
					</div>
					)}
				</ContentContext.Consumer>
				{/* <img  src={navhighlight} style={style} alt="navhighlight"/> */}

			</div>
		)
	}
}

