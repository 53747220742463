import React from 'react';
import { Link } from 'react-router-dom';

import '../lib/Crab.css';

const style = {
	crab_body: {
		position: 'absolute',
		width: 'calc(100% - 8px)',
		height: 'auto',
		left: 0,
		top: '39.1%',
	},
	crab_left_arm: {
		position: 'absolute',
		width: 'calc(34% - 8px)',
		height: 'auto',
		left: '20.2%',
		top: '29.2%',
		transformOrigin: '60% 100%',
		transform: 'rotate(0deg)',
		animation: 'wave_left_arm 1s infinite ease',
		animationDelay: '1s',
	},
	crab_right_arm: {
		position: 'absolute',
		width: 'calc(29.2% - 8px)',
		height: 'auto',
		left: '53.6%',
		top: '26.3%',
		transformOrigin: '40% 100%',
		transform: 'rotate(0deg)',
		animation: 'wave_right_arm 1s infinite ease',
		animationDelay: '1s',
	},
}

export default class Crab extends React.Component {
	render() {
		return (
			<Link to='/Galleri' style={{textIndent: '100%', overflow: 'hidden'}} className={this.props.hide ? 'crab hide' : this.props.hideRight ? 'crab hideRight' : this.props.toTop ? 'crab toTop'  : 'crab'}>
			Galleri
				<div>
					<img style={style.crab_left_arm} src="/img/Crab/small/crab_left_arm.png" alt="crab left arm"/>
					<img style={style.crab_right_arm} src="/img/Crab/small/crab_right_arm.png" alt="crab right arm"/>
					<img style={style.crab_body} src="/img/Crab/small/crab_body_text.png" alt="crab body"/>
				</div>
			</Link>
		)
	}
}