import React from 'react';

import CustomMap from './CustomMap';

import './css/Maps.css';

export default class Maps extends React.Component {
	state={
		iframeContainer: React.createRef(),
		update: 0,
	}
	render() {
		return (
			<div ref={this.state.iframeContainer} className='map-iframe-wrapper'>
				<CustomMap/>
			</div>
		)
	}
}