import React, { Component, Fragment} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Routes from './routes';

import LanguageProvider from './Provider/LanguageProvider';
import ContentProvider from './Provider/ContentProvider';

import Events from './components/Events';
import EventPage from './components/EventPage';
import './App.css';
import './lib/metaslider/assets/metaslider/public.css';

function App() {
  return (
    <div className="App" style={{background: '#a9d9fe'}}>
      <LanguageProvider>
        <ContentProvider>
          <Router>
            <Fragment>
              {/* <Route exact path="/" component={Events} />
              <Route exact path="/event/:id" component={EventPage} /> */}
              
              <Routes />
            </Fragment>
          </Router>
        </ContentProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;
