import React from 'react';
import ContentContext from '../config/ContentContext';
import {createBrowserHistory}  from 'history';
// import history  historyObject from 'history';
const history = createBrowserHistory();

var classNames = require('classnames');
 

export default class PageContentWrapper extends React.Component {
	state={
		content: React.createRef()
	}

	componentDidMount(){
		history.listen((location, action) => {
			console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
			console.log(`The last navigation action was ${action}`)
		  })
		// this.state.content.current.classList.add("hidden");
		this.state.content.current.classList.add("pageFlip");
		setTimeout(function(){ this.revealContent(); }.bind(this), 300);

		// let deferredPrompt;
		// window.addEventListener('beforeinstallprompt', (e) => {
		// // Prevent Chrome 67 and earlier from automatically showing the prompt
		// e.preventDefault();
		// // Stash the event so it can be triggered later.
		// deferredPrompt = e;

		//   // Show the prompt
		//   deferredPrompt.prompt();
		//   // Wait for the user to respond to the prompt
		//   deferredPrompt.userChoice
		// 	.then((choiceResult) => {
		// 	  if (choiceResult.outcome === 'accepted') {
		// 		console.log('User accepted the A2HS prompt');
		// 	  } else {
		// 		console.log('User dismissed the A2HS prompt');
		// 	  }
		// 	  deferredPrompt = null;
		// 	});
		// });
	}

	revealContent(){
		// this.state.content.current.classList.remove("hidden");
		if (this.state.content.current)
			this.state.content.current.classList.remove("pageFlip");
	}

	render() {

		var pageClassesBlur = classNames({
			'page-content': true,
			'blur': true,
			'pageLift': true,
		});
		var pageClasses = classNames({
			'page-content': true,
			'blur': false,
			'pageLift': false,
		});
		var pageClassesNoAnimation = classNames({
			'page-content': true,
			'blur': false,
			'pageLift': false,
			'animation-stop': true
		});


		return (
			<ContentContext.Consumer>
			{Content => (
				<main>
					<article ref={this.state.content} className={ classNames({'page-content': true, 'blur': Content.blur, 'pageLift': Content.blur, 'animations': Content.animations })  /*Content.blur ? pageClassesBlur : pageClasses*/}>
						{this.props.children}
					</article>
				</main>
			)}
			</ContentContext.Consumer>
		)
	}
}