import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import SyncLoader from "react-spinners/SyncLoader";

export class EventPage extends Component {
    state = {
        event: {},
        isLoaded: false
    }
    componentDidMount() {
        axios.get(`/wordpress/wp-json/wp/v2/events/${this.props.match.params.id}`)
        .then(res => this.setState({
            event: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }

    render() {
        const { event, isLoaded } = this.state;
        if(isLoaded) {
            return (
                <Fragment>
                    <Link to='/'>Go Back</Link>
                    <hr/>
                    <h1>{event.title.rendered}</h1>
                    <div dangerouslySetInnerHTML={{ __html: event.content.rendered }}></div>
                    <h4>Organizer: { event.acf.organizer }</h4>
                </Fragment>
            )
        }
        return  <SyncLoader
                    css={`
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 25px;
                        width: 57px;
                    `}
                    size={15}
                    color={"#2f6bb1"}
                    loading={true}
                />

    }
}

export default EventPage
