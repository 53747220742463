import React from 'react';
import parse from 'html-react-parser'

import PageContentWrapper from '../components/PageContentWrapper';
import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import SyncLoader from "react-spinners/SyncLoader";

import PlakatSlider from '../components/PlakatSlider';
import BusinessCard from '../components/BusinessCard';

import LanguageContext from '../config/LanguageContext';

// import draftToHtml from 'draftjs-to-html';


class BaseKunstKultur extends React.Component {

	state = { events: [] }

	componentDidMount () {		
		fetch(`/wordpress/wp-json/wp/v2/events/?per_page=100`)
		.then(res => res.json())
		.then(events =>  this.setState({ events }));

	}

	render () {
		return (
			<article className='Page KunstKultur'>
				<GedserSign/>
				<VerticalNavigation/>
				
				<PageContentWrapper>
					<h1>Kunst & Kultur</h1>

					<div id="content" style={{paddingBottom: 200}}>
						{ this.state.events.length
							? this.state.events.map((event)=> event.acf.language === this.props.language && <><h2>{parse(event.title.rendered)}</h2> {parse(event.acf.article)} <hr/></>)
							: <SyncLoader
								css={`
									margin-left: auto;
									margin-right: auto;
									margin-bottom: 25px;
									width: 57px;
								`}
								size={15}
								color={"#2f6bb1"}
								loading={true}
						/> }

						{ this.props.language === 'da-DK' 
							? <> <div>
									<BusinessCard/>
									<p>Skagensmaleren <b>Ole Otto Ernst</b> er flyttet fra Danmarks nordligste by til den sydligste. Velkommen i Gedser. Galleriet er åbnet tirsdag til søndag fra kl. 11 til 16. Hans flotte malerier ligger i prisklassen 7000 kr til 18.000 kr. Print af billederne kan købes for 100 kr. per stk eller 3 for 200 kr. Han maler også portrætter, pris forhandles individuelt.</p>
									<hr/>
								</div>
								<div>
									<PlakatSlider/>
									<p>De flotte Gedserplakater er tegnet af <b>Susanne Helmark</b> kan bestilles på e-mail: <a href="mailto:helmark@stofanet.dk">helmark@stofanet.dk</a>.</p>
									<hr/>
								</div>
								<div>
									<p style={{textAlign: "center"}}><strong>Hvis du vil vide mere om aktuelle begivenheder i hele lollandfalsterområdet, så tag et kig på lf-kalenderen</strong></p>
								</div>
								<a className='noText' href="https://lf-kalenderen.dk/?ai1ec=action~stream%7Ctag_ids~378%7Crequest_format~json">lf-kalenderen<img className="custom-btn aligncenter" src="/img/KunstKultur/lf-kalenderen.svg" alt="lf-kalenderen logo" /></a>
							</>
							: <> <div>
									<p>Gallerie geöffnet: Dienstag - Sonntag 11 - 16 Uhr.</p>
									<BusinessCard/>
									<hr/>
								</div>
								<div>
									<PlakatSlider/>
									<p>Die Gedser Plakate von <b>Susanne Helmark</b> können hier bestellt werden: e-mail: <a href="mailto:helmark@stofanet.dk">helmark@stofanet.dk</a>.</p>
									<hr/>
								</div>
								<div>
									<p style={{textAlign: "center"}}><strong>Weitere Informationen zu aktuellen Ereignissen im gesamten Lolland Falster-Gebiet finden Sie im LF-Kalender</strong></p>
								</div>
								<a className='noText' href="https://lf-kalenderen.dk/?ai1ec=action~stream%7Ctag_ids~378%7Crequest_format~json">lf-kalenderen<img className="custom-btn aligncenter" src="/img/KunstKultur/lf-kalenderen.svg" alt="lf-kalenderen logo" /></a>
							</>
						}
				
					</div>
				</PageContentWrapper>
			</article>
		);
	}
}

// higher order component (HOC) for accessing language consumer in Sevaerdiheder fetch
export default class KunstKultur extends React.Component {

	render () {
		return (
			<LanguageContext.Consumer>
				{({ language }) =>
				<BaseKunstKultur language={language} />
				}
			</LanguageContext.Consumer>
		)}
} 

// function PageContent() {
// 	return (	
// 	 <div style={{maxWidth: '100%', paddingRight: 15,}}>
// 	 	<BusinessCard/>
// 		 <p>Skagensmaleren <b>Ole Otto Ernst</b> er flyttet fra Danmarks nordligste by til den sydligste. Velkommen i Gedser. Galleriet er åbnet tirsdag til søndag fra kl. 11 til 16. Hans flotte malerier ligger i prisklassen 7000 kr til 18.000 kr. Print af billederne kan købes for 100 kr. per stk eller 3 for 200 kr. Han maler også portrætter, pris forhandles individuelt.</p>

// 		 <hr/>

// 	 	<PlakatSlider/>
// 		 <p>De flotte Gedserplakater er tegnet af <b>Susanne Helmark</b> kan bestilles på e-mail: <a href="mailto:helmark@stofanet.dk">helmark@stofanet.dk</a>.</p>

// 		 <hr/>

// 		<img src="/img/KunstKultur/small/byport-300x300.jpg" alt="gedser" />
		 
// 		<p>Aktuelle begivenheder i Gedser og omegn finder man ud over her også på Facebooksiden: <strong>Gedser/Gedesby - natur, kultur og turisme</strong></p>
// 		<a href="https://www.facebook.com/gedsernaturkulturturisme/">https://www.facebook.com/gedsernaturkulturturisme/</a>

// 		<hr/>
		 
// 		<h2 style={{textAlign: "center"}}><strong>Jazz på Drejersgården</strong></h2>
// 		<p style={{textAlign: "center"}}>10. juli 2018</p>
// 		<img src="/img/KunstKultur/small/sommerjazz_small.jpg" alt="Sommerjazz på Drejersgården" />

// 		<hr/>

// 		<h2 style={{textAlign: "center"}}><strong>Gedser Fyrstival</strong></h2>
// 		<h3 style={{textAlign: "center"}}>10. - 12. august 2018</h3>
// 		<img src="/img/KunstKultur/small/kk1.jpg" alt="Gedser Fyrstival" />
// 		<p>Gedser og Danmarks Sydspids vil være fyldt med oplevelser inden for kultur og natur: gadeteater, musik, kunst, koncerter, danseopvisning, koncerter, film, dragebygning og -flyvning, lokalhistorie, boder. Det gule Palæ, MatadorStationen</p>
// 		<p>I år har Gedser Fyrstival ”Olsen Banden” som tema, da det er 50 år siden, den første film i serien havde premiere.</p>
		 
// 		<p><img src="/img/KunstKultur/small/Jes-Holtsoe-Morten-Wittrock-John-Chipman.jpg" alt="Jes Holts, Morten Wittrock, John Chipman"/></p>

// 		<p>I den anledning har vi fået en stor gave af SN’s erhvervs- og kulturfond, der har givet støtte til, at vi har kunnet hyre Jes Holtsø med Morten Wittrock på klaver og amerikanske John Chipman på trommer.</p>
// 		<p>Det bliver afslutningen på Gedser Fyrstival 2018 med et brag af en koncert i Scandlines Portalen, søndag den 12. august fra kl. 17-ca. 18.30. Olsen Bandens yngste medlem – Børge – er blevet til en voksen og følsom Jes Holtsø, og er blevet en efterspurgt blues- og rocksanger.</p>
// 		<img src="/img/KunstKultur/small/rakkerpak-210x300.jpg" alt="rakkerpak" />

// 		<FyrstivalSlider/>
		
// 		<p>Brandaktuelle infos findes på Facebook: <a href="https://www.facebook.com/events/1595220967259151/?active_tab=discussion">https://www.facebook.com/events/1595220967259151/?active_tab=discussion</a></p>


// 		<hr/>
		 
// 		<div>
// 		<h2 style={{textAlign: "center"}}><strong>Udstillinger på Gedser Odde</strong></h2>
// 		<img src="/img/KunstKultur/small/kk3.jpg" alt="Gedser Odde Udstilling" />
// 		</div>

// 		<hr/>
		 
// 		<div>
// 		<h2 style={{textAlign: "center"}}><strong>Rundt i Guldborgsund - Gedser Odde</strong></h2>
// 		</div>
// 		<div>
// 		<p style={{textAlign: "center"}}><strong>Torsdag, 26. Juli 2018, 15:00</strong></p>
// 		<p style={{textAlign: "center"}}>Naturvejlederen går til spidsen af Odden og fortæller om fugle, sten og de "sære" foldninger i skrænten.</p>
// 		<div>
// 		<p style={{textAlign: "center"}}>Entré 20 kr. Gratis u. 18 år. Mødested p.pladsen ved Gedser Odde mellem fyret og sydspidsen.</p>

// 		<hr/>

// 		</div>
// 		</div>
// 		<h2 style={{textAlign: "center"}}><strong>Gedser Marked</strong></h2>
// 		<div style={{textAlign: "center"}}>
// 		<img src="/img/KunstKultur/small/kk2.jpg" alt="Gedser Marked 2018" />

// 		<hr/> 
		 
// 		<h2 style={{textAlign: "center"}}><strong>Gedesby Mølle sommermarked</strong></h2>
// 		</div>
// 		<div>
// 		<img src="/img/KunstKultur/small/Gedesby_Muehle-214x300.jpg" alt="Gedesby Mølle" />
// 		<p style={{textAlign: "center"}}><strong>Lørdag, 14. Juli 2018 -  10:00 t</strong><strong>il Lørdag, 21. Juli 2018 - 16:00</strong></p>
// 		<p style={{textAlign: "center"}}>Se også møllens hjemmeside.</p>
// 		<p style={{textAlign: "center"}}><a href="https://www.gedesbymoelle.dk">https://www.gedesbymoelle.dk</a></p>
// 		</div>

// 		<hr/>
		
// 		<div>
// 		<p style={{textAlign: "center"}}><strong>Hvis du vil vide mere om aktuelle begivenheder i hele lollandfalsterområdet, så tag et kig på lf-kalenderen</strong></p>
// 		</div>
// 		<a className='noText' href="https://lf-kalenderen.dk/?ai1ec=action~stream%7Ctag_ids~378%7Crequest_format~json">lf-kalenderen<img className="custom-btn aligncenter" src="/img/KunstKultur/lf-kalenderen.svg" alt="lf-kalenderen logo" /></a>
// 	</div>
// 	);
//  }