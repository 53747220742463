import React from 'react';

import PageContentWrapper from '../components/PageContentWrapper';
import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';

import Maps from '../components/Maps';

export default class Kort extends React.Component {
	state = {
		blur: false
	}
	blurContent(){
		this.setState({blur: true})
	}
	unblurContent(){
		this.setState({blur: false})
	}
	render() {
		return (
			<article className='Page Kort'>
				<GedserSign/>
				<VerticalNavigation/>
				
				<PageContentWrapper>
					<Maps/>
				</PageContentWrapper>
			</article>
		)
	}
};
