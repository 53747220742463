import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Language from './Language';

import Kort from './Kort';
import KunstKultur from './KunstKultur';
import MadDrikke from './MadDrikke';
import Overnatninger from './Overnatninger';
import Taksigelse from './Taksigelse';
import Galleri from './Galleri';

import Quiz from './Quiz';

import Sevaerdigheder from './Sevaerdigheder';
import SevaerdighedDetail from './SevaerdighedDetail';

import GedserMap from './GedserMap';

import Impressum from './Impressum';
import Datenschutz from './Datenschutz';


export default () => (
		<Switch>
			<Route exact path='/' component={Language} />
			<Route exact path='/Language' component={Language} />

			<Route exact path='/Kort' component={Kort} />
			<Route exact path='/KunstKultur' component={KunstKultur} />
			<Route exact path='/MadDrikke' component={MadDrikke} />
			<Route exact path='/Overnatninger' component={Overnatninger} />
			<Route exact path='/Taksigelse' component={Taksigelse} />
			<Route exact path='/Galleri' component={Galleri} />

			<Route exact path='/Quiz' component={Quiz} />

			<Route exact path='/Sevaerdigheder' component={Sevaerdigheder} />
				<Route exact path='/Sevaerdigheder/:attraction' component={SevaerdighedDetail} /> 

			<Route exact path='/GedserMap' component={GedserMap} />

			<Route exact path='/Impressum' component={Impressum} />
			<Route exact path='/Datenschutz' component={Datenschutz} />
		</Switch>
);
