import React from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import SyncLoader from "react-spinners/SyncLoader";

// const photos = [
// 	{ src: '/img/galleri/fiskerihavn2.jpg', width: 1, height: 1, alt: 'fiskerihavn' },
// 	{ src: '/img/galleri/kroghage-frost.jpg', width: 10, height: 6, alt: 'kroghage' },
// 	{ src: '/img/galleri/birkemose_strand_2.jpg', width: 9, height: 7, alt: 'birkemose strand' },
// 	{ src: '/img/galleri/birkemose_strand.jpg', width: 4, height: 3, alt: 'birkemose strand' },
// 	{ src: '/img/galleri/birkemose.jpg', width: 4, height: 3, alt: 'birkemose' },
// 	{ src: '/img/galleri/faerge.jpg', width: 1, height: 1, alt: 'faerge' },
// 	{ src: '/img/galleri/gedesby.jpg', width: 9, height: 6, alt: 'gedesby' },
// 	{ src: '/img/galleri/gedser_fra_vand.jpg', width: 9, height: 7, alt: 'gedser fra vand' },
// 	{ src: '/img/galleri/gedser_fugle.jpg', width: 12, height: 16, alt: 'gedser fugle' },
// 	{ src: '/img/galleri/gedser_odde_skulptur.jpg', width: 9, height: 7, alt: 'gedser odde skulptur' },
// 	{ src: '/img/galleri/gedser_odde_strand.jpg', width: 10, height: 7, alt: 'gedser odde strand'},
// 	{ src: '/img/galleri/himmel.jpg', width: 13, height: 10, alt: 'himmel' },
// 	{ src: '/img/galleri/lystbadehavn.jpg', width: 21, height: 16, alt: 'lystbadehavn' },
// 	{ src: '/img/galleri/lystbadehavn_winter.jpg', width: 13, height: 10, alt: 'lystbadehavn winter' },
// 	{ src: '/img/galleri/odde.jpg', width: 10, height: 6, alt: 'odde' },
// 	{ src: '/img/galleri/odde_strand.jpg', width: 8, height: 2, alt: 'odde strand' },
// 	{ src: '/img/galleri/odde_strand_2.jpg', width: 10, height: 8, alt: 'odde strand 2' },
// 	{ src: '/img/galleri/odden.jpg', width: 10, height: 6, alt: 'odden' },
// 	{ src: '/img/galleri/sten.jpg', width: 1, height: 1, alt: 'sten' },
// 	{ src: '/img/galleri/vand.jpg', width: 10, height: 8, alt: 'vand' },
// 	{ src: '/img/galleri/vinter_2018.jpg', width: 22, height: 16, alt: 'vinter 2018' },
// 	{ src: '/img/galleri/vinter_2.jpg', width: 22, height: 16, alt: 'vinter' },
// 	{ src: '/img/galleri/vinter_3.jpg', width: 22, height: 16, alt: 'vinter' },
// 	{ src: '/img/galleri/winter.jpg', width: 6, height: 11, alt: 'vinter' },
//   ];
// const photos = [
// 	{ src: '/img/galleri/fiskerihavn2.jpg',  alt: 'fiskerihavn' },
// 	{ src: '/img/galleri/kroghage-frost.jpg', alt: 'kroghage' },
// 	{ src: '/img/galleri/birkemose_strand_2.jpg',  alt: 'birkemose strand' },
// 	{ src: '/img/galleri/birkemose_strand.jpg', alt: 'birkemose strand' },
// 	{ src: '/img/galleri/birkemose.jpg', alt: 'birkemose' },
// 	{ src: '/img/galleri/faerge.jpg',  alt: 'faerge' },
// 	{ src: '/img/galleri/gedesby.jpg',  alt: 'gedesby' },
// 	{ src: '/img/galleri/gedser_fra_vand.jpg',  alt: 'gedser fra vand' },
// 	{ src: '/img/galleri/gedser_fugle.jpg',  alt: 'gedser fugle' },
// 	{ src: '/img/galleri/gedser_odde_skulptur.jpg',  alt: 'gedser odde skulptur' },
// 	{ src: '/img/galleri/gedser_odde_strand.jpg',  alt: 'gedser odde strand'},
// 	{ src: '/img/galleri/himmel.jpg',  alt: 'himmel' },
// 	{ src: '/img/galleri/lystbadehavn.jpg',  alt: 'lystbadehavn' },
// 	{ src: '/img/galleri/lystbadehavn_winter.jpg',  alt: 'lystbadehavn winter' },
// 	{ src: '/img/galleri/odde.jpg', alt: 'odde' },
// 	{ src: '/img/galleri/odde_strand.jpg',  alt: 'odde strand' },
// 	{ src: '/img/galleri/odde_strand_2.jpg', alt: 'odde strand 2' },
// 	{ src: '/img/galleri/odden.jpg', alt: 'odden' },
// 	{ src: '/img/galleri/sten.jpg',  alt: 'sten' },
// 	{ src: '/img/galleri/vand.jpg', alt: 'vand' },
// 	{ src: '/img/galleri/vinter_2018.jpg',  alt: 'vinter 2018' },
// 	{ src: '/img/galleri/vinter_2.jpg',  alt: 'vinter' },
// 	{ src: '/img/galleri/vinter_3.jpg',  alt: 'vinter' },
// 	{ src: '/img/galleri/winter.jpg',  alt: 'vinter' },
//   ];

  

export default class Galleri extends React.Component {
	constructor() {
		super();
		this.state = { 
			currentImage: 0,
			photos: [], 
			isLoaded: false
		 };
		this.closeLightbox = this.closeLightbox.bind(this);
		this.openLightbox = this.openLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
	}
	componentDidMount(){
		let photos = [];

		console.log(this.props.language);
		
		fetch('/wordpress/wp-json/wp/v2/gallery/?per_page=100')
		.then(res => res.json())
		.then(data => {
			data.forEach(photo => {
					photos.push({ src: photo.acf.image.url, width: photo.acf.image.width, height: photo.acf.image.height, alt: photo.acf[this.props.language]   })		
			})
			this.setState({ photos: photos, isLoaded: true })
		});
	}
	openLightbox(event, obj) {
		this.setState({
			currentImage: obj.index,
			lightboxIsOpen: true,
		});
	}
	closeLightbox() {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		});
	}
	gotoPrevious() {
		this.setState({
			currentImage: this.state.currentImage - 1,
		});
	}
	gotoNext() {
		this.setState({
			currentImage: this.state.currentImage + 1,
		});
	}

	render() {
		if(this.state.isLoaded) {
			return (
				<div className='galleri'>
					<Gallery photos={this.state.photos} onClick={this.openLightbox} />
					<Lightbox images={this.state.photos}
					onClose={this.closeLightbox}
					onClickPrev={this.gotoPrevious}
					onClickNext={this.gotoNext}
					currentImage={this.state.currentImage}
					isOpen={this.state.lightboxIsOpen}
					/>
				</div>
			)
		}	
		return 	<SyncLoader
					css={`
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 25px;
						width: 57px;
					`}
					size={15}
					color={"#2f6bb1"}
					loading={true}
				/>
	}
}