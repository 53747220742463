import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import axios from 'axios';

export class EventItem extends Component {
    state = {
        imgUrl: '',
        author: '',
        isLoaded: false
    }

    static propTypes = {
        event: PropTypes.object.isRequired
    }

    componentDidMount() {
        const { featured_media, author } = this.props.event;
        const getImageUrl = axios.get(`/wordpress/wp-json/wp/v2/media/${featured_media}`);
        const getAuthor = axios.get(`/wordpress/wp-json/wp/v2/users/${author}`);

        Promise.all([getImageUrl, getAuthor]).then(res => {
            this.setState({
                imgUrl: res[0].data.media_details.sizes.full.source_url,
                author: res[1].data.name,
                isLoaded: true
            });            
        });
    }

    render() {
        const { id, title, excerpt } = this.props.event;
        const { imgUrl, author, isLoaded } = this.state;
        
        if(isLoaded){
            return (
                <div>
                    <h2 style={{ marginBottom: '0' }}>{ title.rendered }</h2>
                    <small>Event by <strong> {author } </strong></small>
                    <img
                        style={{ width: '100%' }}
                        src={ imgUrl }
                        alt={title.rendered}
                    />
                    <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} 
                    />
                    <Link to={`/event/${id}`}>Event Description</Link>
                    <hr/>
                </div>
            );
        }

        return null;
    }
}

export default EventItem
