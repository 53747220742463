import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import ContentContext from '../config/ContentContext';
import LanguageContext from '../config/LanguageContext';

import '../lib/VerticalNavigation.css';

import Crab from './Crab';

const style = {
	Nav: {
		Kort: {
			backgroundImage: "url('/img/VerticalNavigation/small/Map_of_Denmark.jpg')",
			backgroundSize: "auto 90%",
		},
		Sevaerdigheder: {
			backgroundImage: "url('/img/VerticalNavigation/small/stein_rotate.png')",
			backgroundSize: "contain",
		},
		KunstKultur: {
			backgroundImage: "url('/img/VerticalNavigation/small/bernstein.png')",
			backgroundSize: "auto 100%",
		},
		MadDrikke: {
			backgroundImage: "url('/img/VerticalNavigation/small/fisch.png')",
			backgroundSize: "60% auto",
		},
		Overnatninger: {
			backgroundImage: "url('/img/VerticalNavigation/small/muschel-rotate.png')",
		},
		Sprog: {
			backgroundImage: "url('/img/Attractions/svg/sailboat.svg')",
			backgroundSize: "auto 100%",
			// backgroundPosition: '70% 50%',
		},
		Taksigelse: {
			backgroundImage: "url('/img/VerticalNavigation/small/netz_dark.png')",
			backgroundSize: "auto 100%",
			// backgroundPosition: '65% 50%',
		},
	}
}

class VerticalNavigation extends React.Component {
	state = {
		hide: false,
		hideRight: false,
		li1_active: false,
		li2_active: false,
		li3_active: false,
		li4_active: false,
		li5_active: false,
		li6_active: false,
		li7_active: false,
		Impressum_active: false,
		showMenuItems: false,
	}

	hideRight = () => {
		this.setState({hideRight: true});
		
	}
	show = () => {
		this.setState({hideRight: false});
	}
	
	li1SetActive = () => {this.setState({li1_active: true});}
	li2SetActive = () => {this.setState({li2_active: true});}
	li3SetActive = () => {this.setState({li3_active: true});}
	li4SetActive = () => {this.setState({li4_active: true});}
	li5SetActive = () => {this.setState({li5_active: true});}
	li6SetActive = () => {this.setState({li6_active: true});}
	li7SetActive = () => {this.setState({li7_active: true});}
	ImpressumSetActive = () => {this.setState({Impressum_active: true});}
	
	li1RemoveActive = () => {this.setState({li1_active: false});}
	li2RemoveActive = () => {this.setState({li2_active: false});}
	li3RemoveActive = () => {this.setState({li3_active: false});}
	li4RemoveActive = () => {this.setState({li4_active: false});}
	li5RemoveActive = () => {this.setState({li5_active: false});}
	li6RemoveActive = () => {this.setState({li6_active: false});}
	li7RemoveActive = () => {this.setState({li7_active: false});}
	ImpressumRemoveActive = () => {this.setState({Impressum_active: false});}

	li7OnMouseOver = () => {
		this.hideRight();
		this.li7SetActive();	
	}
	li7OnMouseLeave = () => {
		this.show();
		this.li7RemoveActive();
	}

	componentDidMount(){
		setTimeout(function () {if (this.props.hide) this.setState({hide: true}); else this.setState({hide: false}); }.bind(this), 10);

	}

  render() {
    return (
		<div>
			<LanguageContext.Consumer>
				{({ language }) =>
					<ContentContext.Consumer>
						{Content => (
							<div id="nav-wrapper">
								<ul id="vertical-nav" className={this.state.hide ? 'hide' : this.state.showMenuItems ? 'show' : ''} onClick={this.props.history.listen(() => {Content.undoBlur();})}>
									<li data-content={(language == "da-DK") ? "Kort" : "Karte"} style={style.Nav.Kort}  className={this.state.li1_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li1SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li1RemoveActive();} }>
										<Link to='/Kort'>Kort</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Seværdigheder" : "Sehenswürdigkeiten"} style={style.Nav.Sevaerdigheder}  className={this.state.li2_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li2SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li2RemoveActive();} }>
										<Link to='/Sevaerdigheder'>Sevaerdigheder</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Kunst & Kultur" : "Kunst & Kultur"} style={style.Nav.KunstKultur}  className={this.state.li3_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li3SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li3RemoveActive();} }>
										<Link to='/KunstKultur'>Kunst & Kultur</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Mad & Drikke" : "Essen & Trinken"} style={style.Nav.MadDrikke}  className={this.state.li4_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li4SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li4RemoveActive();} }>
										<Link to='/MadDrikke'>Mad & Drikke</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Overnatninger" : "Unterkunft"} style={style.Nav.Overnatninger}  className={this.state.li5_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li5SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li5RemoveActive();} }>
										<Link to='/Overnatninger'>Overnatninger</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Sprog" : "Sprache"} style={style.Nav.Sprog}  className={this.state.li6_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.li6SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.li6RemoveActive();} }>
										<Link to='/'>Sprogvalg</Link>
									</li>
									<li data-content={(language == "da-DK") ? "Tak" : "Danke"} style={style.Nav.Taksigelse}  className={this.state.li7_active ? 'active' : '' } onMouseOver={() => {Content.doBlur(); this.hideRight(); this.li7SetActive();} } onMouseLeave={() => {if (!this.state.showMenuItems) Content.undoBlur(); this.show(); this.li7RemoveActive();} }>
										<Link to='/Taksigelse'>Tak</Link>
									</li>
								</ul>
								<div id="waves-menu-btn" className={this.state.hide ? 'hide' : ''} onClick={()=>{if (this.state.showMenuItems) {Content.undoBlur(); this.setState({showMenuItems:false});} else {Content.doBlur(); this.setState({showMenuItems:true});} }} >
									<img src={this.state.showMenuItems ? "/img/VerticalNavigation/wave-x.svg" : "/img/VerticalNavigation/menu-waves.svg"} alt="menu waves" />
								</div>
							</div>
						)}
					</ContentContext.Consumer>
				}
			</LanguageContext.Consumer>
			
			{this.props.hideCrab ? '' : <Crab hideRight={this.state.hideRight}/>}
			<div id="QuizLink" className={this.state.hide ? 'hide' : ''}>
				<Link to='/Quiz'><img src="/img/VerticalNavigation/small/seestern_small.png" alt="Quiz Seestern"/>Impressum</Link>
			</div>
			<div id="impressum" className={this.state.Impressum_active ? 'active' : '' } onMouseOver={this.ImpressumSetActive } onMouseLeave={this.ImpressumRemoveActive }>
				<Link to='/Impressum'><img src="/img/VerticalNavigation/impressum_white.png" alt="Impressum Feder"/>Impressum</Link>
			</div>
		</div>
    )
  }
}

export default withRouter(VerticalNavigation);
