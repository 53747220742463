import React from 'react';
import './css/IframeTv.css';

export default class IframeTv extends React.Component {
	render() {
		return (
			<div className="iframe-tv">
				<img src="/img/galleri/tv/screen_overlay.png" alt="tv screen" className="screen-background"/>
				<iframe title="Gedser ligger lige midt i verden" width="560" height="315" src="https://www.youtube.com/embed/hVQWB76zn7U?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
				<img src="/img/galleri/tv/screen_overlay.png" alt="tv screen" className="screen-overlay"/>
				<img src="/img/galleri/tv/frame_overlay.png" alt="tv frame" className="frame-overlay"/>
			</div>
		)
	}
}