import React from 'react';
import parse from 'html-react-parser'

import PageContentWrapper from '../components/PageContentWrapper';
import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import SyncLoader from "react-spinners/SyncLoader";

import LanguageContext from '../config/LanguageContext';

// import draftToHtml from 'draftjs-to-html';


class BaseMadDrikke extends React.Component {

	state = { foods: [] }

	componentDidMount () {
		fetch(`/wordpress/wp-json/wp/v2/restaurants/?per_page=100`)
		.then(res => res.json())
		.then(foods =>  this.setState({ foods }));
	}

	render () {
		return (
			<article className='Page MadDrikke'>
				<GedserSign/>
				<VerticalNavigation/>
				
				<PageContentWrapper>
					<h1>{this.props.language == "da-DK" ? 'Mad & Drikke' : 'Essen & Trinken'}</h1>

					<div id="content" style={{paddingBottom: 200}}>
						{ this.state.foods.length
						? this.state.foods.map((food)=> food.acf.language === this.props.language && <><h2>{parse(food.title.rendered)}</h2> {parse(food.acf.article)} <hr/></>)
						: <SyncLoader
							css={`
								margin-left: auto;
								margin-right: auto;
								margin-bottom: 25px;
								width: 57px;
							`}
							size={15}
							color={"#2f6bb1"}
							loading={true}
						/> }
				</div>
			</PageContentWrapper>
		</article>
		);
	}
}

// higher order component (HOC) for accessing language consumer in Sevaerdiheder fetch
export default class MadDrikke extends React.Component {

	render () {
		return (
			<LanguageContext.Consumer>
				{({ language }) =>
				<BaseMadDrikke language={language} />
				}
			</LanguageContext.Consumer>
		)}
} 


// export default () => (
// 	<article className='Page MadDrikke'>
// 		<GedserSign/>
// 		<VerticalNavigation/>
		
// 		<PageContentWrapper>
// 			<h1>Mad & Drikke</h1>
// 			<PageContent/>
// 		</PageContentWrapper>
// 	</article>
// );

// function PageContent() {
// 	return (
// 	  <div style={{maxWidth: '100%', paddingRight: 15,}}>
// 		<h2>Gedser Røgeri</h2>
		// <img className="alignleft" src="/img/MadDrikke/9497014ea1acba7518dd375aab7e9e20.jpg" alt="Gedser Røgeri" />
		// <h4>Havnegade 2. 4874 Gedser. Tel: 20 10 30 75. Email: <a href="mailto:info@gedser-roegeri.dk" target="_self">info@gedser-roegeri.dk</a></h4>
		// <h4><a href="https://www.gedser-roegeri.dk/">https://www.gedser-roegeri.dk</a></h4>
		// <h4><strong>Åbningstider: </strong></h4>
		// <h4>Gedser Røgeri er åbent fra påsken til medio september, se internetsiden</h4>
		// <h4>Her er mulighed for at købe delikate og lokale røgvarer med en hyggelig stemning og gæstfri betjening.  Gedser Røgeri serverer sommerens retter på terrasserne. Nyd de friskrøgede varer til en vand, en øl eller et glas vin. I sommersæsonen inviterer Gedser Røgeri til festlige arrangementer.</h4>
		

// 		<hr/>

		
// 		<h2>Restaurant Najaden</h2>
		// <div className="_5aj7 _3-8j _20ud">
		// <div className="_4bl9">
		// <img className="size-medium wp-image-943 alignleft" src="/img/MadDrikke/najaden-300x188.jpg" alt="Restaurant Najaden" />
		// <h4><span className="_2iem">Gedser lystbadehavn. Vestre strand 3. 4874 </span><span className="_2iem">Gedser. Tel:</span> 54 17 02 87</h4>
		// </div>
		// </div>
		// <h4><a href="https://najaden.dk">https://najaden.dk</a></h4>
		// <h4><strong>Åbningstider:</strong></h4>
		// <h4>lavsæson (fra den 12. maj) Mandag til søndag 18:00 til 21:00
		// køkkenet er åbent fra 18:00 til 20:00
		// højsæson (7. juli til 12. august) Mandag til søndag 12:30 til 23:00
		// køkkenet er åbent fra 12:30 til 15:00 og 18:00 til 22:00</h4>
		

// 		<hr/>

		
// 		<h2>Toldcafeen</h2>
		// <img className="size-medium wp-image-1099 alignleft" src="/img/MadDrikke/toldcafeen-300x146.jpg" alt="Toldcafeen" />
		// <h4>Stationsvejen 1, 4874 Gedser.  Tel: 4188 6464</h4>
		// <a href="http://toldcafeen.dk/">http://toldcafeen.dk</a>
		// <h4>Åbningstider:</h4>
		// <p><strong>Mandag til fredag kl. 12-18.</strong></p>
		// <em>Køkkenet lukker 1 time før. </em>Mulighed for arrangementer i weekenden.
		// <h4>Toldcaféens elever og personale tilbyder dejlig mad med et strejf af nostalgi med vægt på god service og årstidernes råvare.</h4>
		

// 		<hr/>

		
// 		<h2>Pizzaria</h2>
		// <img className="size-medium wp-image-1102 alignleft" src="/img/MadDrikke/pizzaria-300x225.jpg" alt="Pizzaria" />
		// <h4>Gedser Landevej 58, 4874 Gedser. Tel: 54 43 11 11</h4>
		// <h4><strong>Åbningstider: </strong></h4>
		// <h4>Mandag til torsdag: 14.00 - 20.00. Fredag til søndag: 11.00 - 22.00</h4>
		

// 		<hr/>

		
// 		<h2>Havnekroen</h2>
		// <img className="size-medium wp-image-1120 alignleft" src="/img/MadDrikke/havnekroen-1-300x225.jpg" alt="Havnekroen" />
		// <h4>Havnegade 19. 4874 Gedser.  Tel: 54170000</h4>
		// <a href="http://www.havnekroen-gedser.dk/?id=377375">http://www.havnekroen-gedser.dk/?id=377375</a>
		// <h4><strong>Åbningstider:</strong></h4>
		// <h4>Dagligt 12.00 - 20.00. Dagens ret Fra kl. 17,00-19,30</h4>
		// <h4>Selskaber op til 40 kuverter, med eller uden musik. Også mad ud af huset</h4>
		// <h4>Havnekroen er Danmarks sydligste kro og ligger smukt placeret på havnen med udsigt over denne samt Østersøen til Tyskland, hvor færgerne dagligt sejler til og fra.</h4>
		

// 		<hr/>

		
// 		<h2>Bodega</h2>
		// <img className=" wp-image-1123 alignleft" src="/img/MadDrikke/bodega-300x225.jpg" alt="Gedser Bodega" />
		// <h4>Langgade 47. 4874 Gedser.</h4>
		// <h4><strong>Åbningstider:</strong></h4>
		// <h4>Mandag til Torsdag 14.00 - 21.00, Fredag/Lørdag 14.00 - 2.00, Søndag 12.00 - 18.00</h4>
		

// 		<hr/>

		
// 		<h2>Dagli Brugsen</h2>
		// <img className="size-medium wp-image-1118 alignleft" src="/img/MadDrikke/brugsen-1-300x210.jpg" alt="Dagli Brugsen" />
		// <h4>Erik Schiødts Gade 2. 4874 Gedser. Tel: 5417-9309</h4>
		// <h4>Åbningstider:</h4>
		// <p><strong>Dagli Brugsen med Postshop: 365 Dage om året 8.00 - 19.00. <br/>
		// Sommer åbningstider: <br/>
		// lørdag 23. Juni - søndag 12. August - hver dag 7 dage om ugen - kl 07:00 - kl 21:00 <br/>
		// OK Tankstation åbent døgnet rund</strong></p>
		

// 		<hr/>

		
// 		<h2>Købmandsgården</h2>
		// <h4>Gedser Landevej 79. 4874 Gedser. Tel: 54170097, Mob: 61714688 eller 29718044</h4>
		// <img src="/img/MadDrikke/apple-pie.jpg" alt="kage" />
		// <p>Købmandsgaarden sælger god kaffe, lækker hjemmebag  og økologisk hjemmelavet is. </p>
		// <h4>Åbningstider:</h4>
		// <p><strong>Fra 1.6 til 7.9 <br/> Tirsdag-søndag: 07.00-17.00</strong></p>
		

// 		<hr/>

		
// 		<h2>Gedesby</h2>
		// <h3><strong>Skovgrillen</strong></h3>
		// <img className="alignleft" src="/img/MadDrikke/32130586_384803765356961_3593301813157167104_n.jpg" alt="Skovgrillen" />
		// <h4>
		// Dillet 11B, Gedesby. 4874 Gedser. Tel: 29339510</h4>
		// <h4><strong>Åbningstider:</strong></h4>
		// <p><strong>Mandag til søndag 12.00 - 20.00</strong></p>
		

// 		<hr/>
		
// 		<h2>Bageri</h2>
		// <img className="wp-image-939 alignleft" src="/img/MadDrikke/gedesbz-bageri-300x177.jpg" alt="Bageri" />
		// <h4>Gammelgade 27. Gedesby. 4874 Gedser</h4>
		// <h4>Åbningstider:</h4>
		// <p><strong>Tirsdag-søndag: 07.00-17.00</strong></p>		
// 	  </div>
// 	);
//   }