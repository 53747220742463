import React from 'react';

import VerticalNavigation from '../components/VerticalNavigation';

import Sailboats from '../components/Sailboats';
import Crab from '../components/Crab';

import LanguageContext from '../config/LanguageContext';

const style = {
	bg: {
		backgroundImage: 'url("/img/background/gedser_meer.jpg")',
		backgroundSize: 'cover',
		padding: 0,
		height: '100vh',
		width: '100vw',
		overflow: 'auto',
	}
}

export default () => (
	<article className='Page Sprogvalg' style={style.bg}>
		<img src="/img/Clouds/gedser_cloud.png" alt="gedser clouds" style={{width: '65%', marginTop: '25vh', transform: 'translateY(-50%)' }}/>
		{/* <LanguageContext.Consumer>
			{({ updateLanguage, language }) => (
			<header> see this site in:
				<select onChange={(e)=>{updateLanguage(e)}}>
				<option value="da-DK">Dansk</option>
				<option value="de-DE">Deutsch</option>
				</select>
				<button onClick={()=>console.log(language)}>lang?</button>
			</header>
			)}
		</LanguageContext.Consumer>); */}
		<Sailboats/>
		<VerticalNavigation hide={true} hideCrab={true}/>
		<Crab hide={true}/>
	</article>
);
