import React from 'react';
import { Link } from 'react-router-dom';

import './css/BusinessCard.css';

export default class BusinessCard extends React.Component {
	render() {
		return (
			<div className='BusinessCard'>
				<img className='bg' src="./img/BusinessCard/otto_bg.jpg"/>
				<img className='left_side' src="./img/BusinessCard/left_side.png" alt="left_side"/>
				<img className='right_side' src="./img/BusinessCard/right_side.png" alt="right_side"/>
			</div>
		)
	}
}