import React from 'react';

import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';
import Crab from '../components/Crab';

import PageContentWrapper from '../components/PageContentWrapper';
import LanguageContext from '../config/LanguageContext';
import Galleri from '../components/Galleri';
import IframeTv from '../components/IframeTv';

export default () => (
	<article className='Page Galleri'>
		<GedserSign/>
		<VerticalNavigation hideCrab={true}/>
		<Crab toTop={true}/>
		
		<PageContentWrapper>
			<h1>Galleri</h1>
			<IframeTv/>
			<LanguageContext.Consumer>
				{({ language }) =>
					<Galleri  language={language}/>
				}
			</LanguageContext.Consumer>
		</PageContentWrapper>
	</article>
);
