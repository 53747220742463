import React from 'react';
import ContentContext from '../config/ContentContext';

export default class ContentProvider extends React.Component {
	
	doBlur = () => {
		this.setState({blur: true});
	}
	undoBlur = () => {
		this.setState({blur: false});
	}
	stopAnimations = () => {
		this.setState({animations: false});
	}
	startAnimations = () => {
		this.setState({animations: true});
	}
	
	state = {
		blur: false,
		animations: true,
		stopAnimations: this.stopAnimations,
		startAnimations: this.startAnimations,
		doBlur: this.doBlur,
		undoBlur: this.undoBlur,
	}

	render() {
		return (
			<ContentContext.Provider value={this.state}>
				<div>
					{this.props.children}
				</div>
			</ContentContext.Provider>
		)
	}
}