import React from 'react';

import Quiz from './QuizComponent/lib/Quiz';
import LanguageContext from '../config/LanguageContext';

import './css/GedserQuiz.css';

const quizDk = {
	"quizTitle": "Hvor godt kender du Gedser?",
	"questions": [
	  {
		"question": "Hvem har tegnet Gedsers byporte?",
		"questionType": "text",
		"answers": [
		  "Jørn Utzon",
		  "P.V. Klint-Jensen",
		  "Philip Rasmussen"
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvilken race har de udsatte vilde heste i Bøtøskoven?",
		"questionType": "text",
		"answers": [
		  "Konik",
		  "Tarpan",
		  "Przewalski"
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvornår åbnede DSB færgeruten fra Gedser til Warnemünde?",
		"questionType": "text",
		"answers": [
		  "1903",
		  "1864",
		  "1945"
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvornår blev den første redningsstation bygget i Gedser?",
		"questionType": "text",
		"answers": [
		  "1968",
		  "1925",
		  "1992",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Hvor mange fugle ringmærker Fuglestationen om året?",
		"questionType": "text",
		"answers": [
		  "Mellem 6000  og 12000",
		  "Mellem 600 og 1200",
		  "Mellem 10000 og 100000",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "I hvilket årstal var den store stormflod som kostede mange mennesker livet og ødelage store dele af byen?",
		"questionType": "text",
		"answers": [
		  "1687",
		  "1872",
		  "1896",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Indtil hvornår var Gedesby Danmarks sydligste by?",
		"questionType": "text",
		"answers": [
		  "1912",
		  "1665",
		  "1886",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvornår blev Gedesby mølle taget i brug?",
		"questionType": "text",
		"answers": [
		  "1911",
		  "1722",
		  "1854",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvor langt væk kunne maan se lyset fra det første trækulsfyr på Odden?",
		"questionType": "text",
		"answers": [
		  "48 km",
		  "6 km",
		  "10 km",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvornår blev det tidligere Militæranlægs mandskabsbygning renoveret og ombygget til formidlingshus?",
		"questionType": "text",
		"answers": [
		  "2012",
		  "1990",
		  "2005",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvor meget vejer sydstenen?",
		"questionType": "text",
		"answers": [
		  "ca. 200 kg",
		  "ca. 4 tons",
		  "ca. 30 tons",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Hvem tegnede Gedser kirke?",
		"questionType": "text",
		"answers": [
		  "Jesper Neergaard",
		  "O.M. Glahn",
		  "P.V. Klint-Jensen",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvad blev bygningen, hvor lokalhistorisk museum ligger, oprindeligt bygget til?",
		"questionType": "text",
		"answers": [
		  "Lokalhistorisk arkiv",
		  "Præstegård",
		  "Skole",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvor mange stjerner har Gedser lystbadehavn?",
		"questionType": "text",
		"answers": [
		  "1",
		  "4",
		  "2",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Hvornår blev Gedser Remise opført?",
		"questionType": "text",
		"answers": [
		  "1886",
		  "2013",
		  "1925",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvem har tegnet Gedser Remise?",
		"questionType": "text",
		"answers": [
		  "H.C. Glahn",
		  "Philip Rasmussen",
		  "Jørn Utzon",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvornår blev de første diger bygget?",
		"questionType": "text",
		"answers": [
		  "1872",
		  "1860",
		  "1500-tallet",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvem byggede de første diger?",
		"questionType": "text",
		"answers": [
		  "Svenskere",
		  "Tyskere",
		  "Hollændere",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvilken funktion havde Gjedzør slot oprindeligt?",
		"questionType": "text",
		"answers": [
		  "Overnatning for kongelige",
		  "Vagttårn",
		  "Militærstrategisk forsvarsbygning",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvem har fremstillet Dialog-talerstolen?",
		"questionType": "text",
		"answers": [
		  "Edward Eriksen",
		  "Robert Jacobsen",
		  "Jesper Neergaard",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "Hvad betyder Gedser?",
		"questionType": "text",
		"answers": [
		  "Gæs",
		  "Gedde",
		  "Gedehyrdens halvø",
		],
		"correctAnswer": "3"
	  },
	  {
		"question": "hvem har tegnet lamperne på torvet?",
		"questionType": "text",
		"answers": [
		  "Søren Pedersen",
		  "Erik Mortensen",
		  "Bent Sørensen",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Gedser Odde ligger på højde med?",
		"questionType": "text",
		"answers": [
		  "Schleswig",
		  "Stettin i polen",
		  "Kiel",
		],
		"correctAnswer": "1"
	  },
	  {
		"question": "Hvor kommer sydstenen fra?",
		"questionType": "text",
		"answers": [
		  "Møns klint",
		  "Skelby",
		  "Gedser",
		],
		"correctAnswer": "2"
	  },
	  {
		"question": "Hvilken verdenssensation er udstillet på det sorte geomuseum?",
		"questionType": "text",
		"answers": [
		  "verdens største ravklump",
		  "verdens største musling",
		  "verdens største slebne 4 takkede stjernealmandin",
		],
		"correctAnswer": "3"
	  },
	//   {
	// 	"question": "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
	// 	"questionType": "photo",
	// 	"answers": [
	// 	  "https://dummyimage.com/600x400/000/fff&text=A",
	// 	  "https://dummyimage.com/600x400/000/fff&text=B",
	// 	  "https://dummyimage.com/600x400/000/fff&text=C",
	// 	  "https://dummyimage.com/600x400/000/fff&text=D"
	// 	],
	// 	"correctAnswer": "1"
	//   }
	]
} 

const quizDe =  {
	"quizTitle": "Wie gut kennen Sie Gedser?",
    "questions": [
      {
        "question": "Wer hat das Stadttor Gedsers gezeichnet?",
        "questionType": "text",
        "answers": [
          "Jørn Utzon",
          "P.V. Klint-Jensen",
          "Philip Rasmussen"
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Welche Rasse haben die ausgesetzten wilden Pferde im Bøtøwald?",
        "questionType": "text",
        "answers": [
          "Konik",
          "Tarpan",
          "Przewalski"
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wann öffnete die DSB Fährverbindung von Gedser nach Warnemünde?",
        "questionType": "text",
        "answers": [
          "1903",
          "1864",
          "1945"
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wann wurde die erste Rettungsstation in Gedser gebaut?",
        "questionType": "text",
        "answers": [
          "1968",
          "1925",
          "1992",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Wie viele Vögel beringt die Vogelstation pro Jahr?",
        "questionType": "text",
        "answers": [
          "Zwischen 6000  und 12000",
          "Zwischen 600 und 1200",
          "Zwischen 10000 und 100000",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "In welchem Jahr war die grosse Sturmflut, die das Leben von vielen Menschen kostete und grosse Teile von Gedser zerstörte?",
        "questionType": "text",
        "answers": [
          "1687",
          "1872",
          "1896",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Bis wann war Gedesby die südlichste Stadt Dänemarks?",
        "questionType": "text",
        "answers": [
          "1912",
          "1665",
          "1886",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wann wurde Gedesby Mühle in Betrieb genommen?",
        "questionType": "text",
        "answers": [
          "1911",
          "1722",
          "1854",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wie weit weg konnte man das Licht von dem ersten Holzkohlenleuchtturm auf der Landzunge sehen?",
        "questionType": "text",
        "answers": [
          "48 km",
          "6 km",
          "10 km",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wann wurde das frühere Mannschaftsgebäude der Militäranlage renoviert und in ein Vermittlungshaus umgebaut?",
        "questionType": "text",
        "answers": [
          "2012",
          "1990",
          "2005",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wieviel wiegt der Südstein?",
        "questionType": "text",
        "answers": [
          "etwa 200 kg",
          "etwa 4 tons",
          "etwa 30 tons",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Wer zeichnete Gedser Kirche?",
        "questionType": "text",
        "answers": [
          "Jesper Neergaard",
          "O.M. Glahn",
          "P.V. Klint-Jensen",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wofür wurde das Gebäude, wo das lokalhistorische Museum liegt, ursprünglich gebaut?",
        "questionType": "text",
        "answers": [
          "Lokalhistorisches Archiv ",
          "Pfarrhaus",
          "Schule",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wie viele Sterne hat Gedser Yachthafen?",
        "questionType": "text",
        "answers": [
          "1",
          "4",
          "2",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Wann wurde Gedser Remise gebaut?",
        "questionType": "text",
        "answers": [
          "1886",
          "2013",
          "1925",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wer hat Gedser Remise gezeichnet?",
        "questionType": "text",
        "answers": [
          "H.C. Glahn",
          "Philip Rasmussen",
          "Jørn Utzon",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wann wurden die ersten Deiche gebaut?",
        "questionType": "text",
        "answers": [
          "1872",
          "1860",
          "1500-tallet",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wer baute die ersten Deiche?",
        "questionType": "text",
        "answers": [
          "Die Schweden",
          "Die Deutschen",
          "Die Holländer",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Welche Funktion hatte Gjedzør Schloss ursprünglich?",
        "questionType": "text",
        "answers": [
          "Übernachtung für die Königlichen",
          "Wachtturm",
          "Militärstrategisches Verteidigungsgebäude",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Wer hat den Dialog-Rednerpult hergestellt?",
        "questionType": "text",
        "answers": [
          "Edward Eriksen",
          "Robert Jacobsen",
          "Jesper Neergaard",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Was bedeutet Gedser?",
        "questionType": "text",
        "answers": [
          "Gänse",
          "Hecht",
          "Die Halbinsel des Ziegenhirtens",
        ],
        "correctAnswer": "3"
      },
      {
        "question": "Wer hat die Lampen auf dem Marktplatz gezeichnet?",
        "questionType": "text",
        "answers": [
          "Søren Pedersen",
          "Erik Mortensen",
          "Bent Sørensen",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Gedser Landzunge liegt auf der Höhe von?",
        "questionType": "text",
        "answers": [
          "Schleswig",
          "Stettin in Polen",
          "Kiel",
        ],
        "correctAnswer": "1"
      },
      {
        "question": "Woher kommt der Südstein?",
        "questionType": "text",
        "answers": [
          "Møns Kreidefelsen",
          "Skelby",
          "Gedser",
        ],
        "correctAnswer": "2"
      },
      {
        "question": "Welche Weltsensation ist im schwarzen Geomuseum ausgestellt?",
        "questionType": "text",
        "answers": [
          "Der grösste Bernstein der Welt",
          "Die grösste Muschel der Welt",
          "Der grösste geschliffene 4-zackige Sternalmandin",
        ],
        "correctAnswer": "3"
      },
    ]
} 

export default class GedserQuiz extends React.Component {
	render() {
		return (
			<div className='GedserQuiz'>
				<LanguageContext.Consumer>
				{({ language }) => (
					<Quiz quiz={language === 'da-DK' ? quizDk : quizDe}/>
				)}
				</LanguageContext.Consumer>
			</div>
		)
	}
}