import React from 'react';

import LanguageContext from '../config/LanguageContext';

const LanguageConsumer = LanguageContext.Consumer;

export default class LanguageProvider extends React.Component {
    state = { language: "da-DK" }

    updateLanguage = e => {this.setState({ language: e.currentTarget.dataset.lang }); console.log(this.state.language);
	}

    render() {
      return (
        <LanguageContext.Provider value={{
          language: this.state.language,
          updateLanguage: this.updateLanguage
        }}>
          {this.props.children}
        </LanguageContext.Provider>
    );}
}