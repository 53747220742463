import React from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import SyncLoader from "react-spinners/SyncLoader";

import './css/AttractionsList.css';
import LanguageContext from '../config/LanguageContext';

class BaseAttractionsList extends React.Component {
	state = {attractions: [], isLoaded: false}
	componentDidMount(){
		console.log(this.props.language);
		
		fetch('/wordpress/wp-json/wp/v2/attractions/?per_page=100')
		// fetch('/attractions/all/'+this.props.language)
		.then(res => res.json())
		.then(attractions => this.setState({ attractions: attractions, isLoaded: true }))
		.then(() => {
			document.querySelectorAll('.rolldown-list li').forEach(function (li, index) {
				var delay = (index / 4) + 's';
				li.style["-webkit-animation-delay"] = delay;
				li.style["-moz-animation-delay"] = delay;
				li.style.animationDelay = delay;
			});
		});
	}
	render() {
		const { attractions, isLoaded } = this.state;
		if(isLoaded)
			return(
				attractions.map(attraction => {					
					if (attraction.acf.language === this.props.language) 
						return <li key={attraction.id}><Link to={'/Sevaerdigheder/'+attraction.id}><p>{parse(attraction.title.rendered)}</p>{attraction.acf.image !== false ? <img src={attraction.acf.image.url} alt={attraction.title}/> : '' }</Link></li>
								
				})
			)
		else 
				return 	<SyncLoader
							css={`
								margin-left: auto;
								margin-right: auto;
								margin-bottom: 25px;
								width: 57px;
							`}
							size={15}
							color={"#2f6bb1"}
							loading={true}
						/>
		// return (
		// 	<ul className="rolldown-list" id="myList">

		// 		{/* {this.state.attractions.map(attraction =>
		// 		// <li key={attraction.id}><Link to={'/Sevaerdigheder/'+attraction.id}><p>{attraction.title}</p>{attraction.image != '' ? <img src={"/img/Attractions/svg/"+attraction.image} alt={attraction.title}/> : '' }</Link></li>
		// 		<li key={attraction.id}><Link to={'/Sevaerdigheder/'+attraction.id}><p>{attraction.title.rendered}</p>{attraction.image != '' ? <img src={"/img/Attractions/svg/"+attraction.image} alt={attraction.title}/> : '' }</Link></li>
		// 		)} */}
				
		// 		{this.state.attractions.map(attraction => 
		// 			{if (attraction.acf.language === this.props.language)
		// 			 <li key={attraction.id}><Link to={'/Sevaerdigheder/'+attraction.id}><p>{attraction.title.rendered}</p>{attraction.image != '' ? <img src={"/img/Attractions/svg/"+attraction.image} alt={attraction.title}/> : '' }</Link></li>}
		// 		)}

				
		// 	</ul> 
		// )
	}
}

// higher order component (HOC) for accessing language consumer in Taksigelse fetch
export default class AttractionsList extends React.Component {
	render () {
		return (
			<ul className="rolldown-list" id="myList">
				<LanguageContext.Consumer>
					{({ language }) =>
						<BaseAttractionsList language={language} />
					}
				</LanguageContext.Consumer>
			</ul> 
		)}
} 