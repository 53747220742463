import React from 'react';

import PageContentWrapper from '../components/PageContentWrapper';

import GedserSign from '../components/GedserSign';
import VerticalNavigation from '../components/VerticalNavigation';

import GradientContainer from '../components/GradientContainer';

export default () => (
	<article className='Page Impressum'>
		<GedserSign/>
		<VerticalNavigation/>
		
		<PageContentWrapper>
			<PageContent/>
		</PageContentWrapper>

	</article>
);

const style = {
	fancyFont: {
		color: '#FFC107',
		fontFamily: '"IM Fell English SC',
		fontSize: '1em',
		// lineHeight: '160px',
		fontWeight: 'normal',
		// marginBottom: '170px',
		marginTop: '30px',
		marginRight: '15px',
		textAlign: 'center',
		textShadow: 'rgb(0, 0, 0) 0px 1px 1px',
		display: 'inline-block',
		// width: '80%',
    	textTransform: 'uppercase'
	}
}

function PageContent() {
	return (
		<GradientContainer>
			<h1>Impressum</h1>			
			<article>
				<h3 style={{borderBottom: '1px solid black'}}>Anbieter</h3>

				<p><b>Naja Sørensen</b></p>
				<p>Hedwigshof 17</p>
				<p>17291 Carmzow-Wallmow</p>

				<h4>Kontakt</h4>

				<p><span>Telefon: </span><span>+49 1749662599</span></p>
				<p><span>E-Mail: </span><a href="mailto:naja_soerensen@gmx.de"><span>naja_soerensen@gmx.de</span></a></p>
			</article>
			<article style={{marginTop: 30}}>
				<h3 style={{borderBottom: '1px solid black'}}>Web Entwickler</h3>
				<p><b>Malik Dimter</b></p>
				<p>Webdeveloper/Webdesigner</p>
				<p>Østre Alle 5</p>
				<p>4874 Gedser</p>

				<h4>Kontakt</h4>

				<p><span>Telefon: </span><span>+45 40304398</span></p>
				<p><span>E-Mail: </span><a href="mailto:malik.dimter@gmail.com"><span>malik.dimter@gmail.com</span></a></p>
			</article>
		</GradientContainer>
	);
  }